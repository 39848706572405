.edmInterviewPhotoGallery img {
  object-fit: cover;
  margin: 10px;
  border-radius: 10px;
  height: 170px;
  width: 170px;
}

.edmInterviewPhotoGallery {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-device-width: 480px) {
  .edmInterviewPhotoGallery img {
    height: 150px;
    width: 150px;
    margin: 5px;
  }
}
