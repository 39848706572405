.whyWebEngineerToResumeText {
  font-size: 25px;
  font-weight: 700;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.whyWebEngineerToResume {
  margin-bottom: 50px;
}

.arrowRightIcon {
  position: relative;
  top: 5px;
  left: 20px;
}

.arrowRightIcon:hover {
  cursor: pointer;
  opacity: 0.8;
}

@media only screen and (max-device-width: 480px) {
  .whyWebEngineerToResumeText {
    font-size: 20px;
  }

  .whyWebEngineerToResumeText > div {
    margin-bottom: 20px;
  }

  .arrowRightIcon {
    display: block;
    position: relative;
    top: 10px;
    left: 5px;
  }
}
