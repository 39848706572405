html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background: #121212;
  color: #efd9ce;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

a {
  color: #efd9ce;
}

.alice-carousel__dots {
  margin: 0;
}

.greenLine {
  background-color: #1db954;
  height: 2px;
  margin-top: 2px;
  margin-bottom: 10px;
}

@media only screen and (max-device-width: 480px) {
  html,
  body {
    font-size: 12px;
  }
}
