.musicNotesContainer {
  margin-top: 100px;
  margin-bottom: 200px;
}

.musicNotes {
  display: block;
  margin: auto;
  position: relative;
  width: 50%;
  min-width: 1em;
  height: 1.5em;
  border: 0px solid #121212;
}

.note1,
.note2,
.note3,
.note4 {
  position: absolute;
  animation: notes 3s infinite linear;
  font-size: 40px;
  opacity: 0;
}

.note1 {
  top: 60px;
  left: 0;
  animation-delay: 0.5s;
}

.note2 {
  top: 30px;
  left: 30%;
  animation-delay: 1s;
}

.note3 {
  top: 90px;
  left: 60%;
  animation-delay: 1.5s;
}

.note4 {
  top: 40px;
  left: 90%;
  animation-delay: 2s;
}

@keyframes notes {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: scale(1.5) translate(50%, -50%);
  }
  80% {
    opacity: 0;
    transform: scale(1.5) translate(100%, -100%);
  }
  100% {
    transform: scale(1.5) translate(100%, -100%);
    opacity: 0;
  }
}

@media only screen and (max-device-width: 480px) {
  .musicNotesContainer {
    display: none;
  }
}
