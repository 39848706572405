.resumeSkillsTitle {
  font-weight: bold;
}

.skillPillContainer {
  display: inline-flex;
  flex-wrap: wrap;
}

.skillPill {
  border: 1px solid #1db954;
  border-radius: 20px;
  padding: 10px;
  margin: 5px;
  margin-left: 0;
}
