.projectSummary {
  display: flex;
  margin-top: 50px;
}

.projectSummary img {
  object-fit: contain;
  border-radius: 10px;
  position: relative;
  bottom: 50px;
}

.projectSummaryDetails {
  border-radius: 10px;
  max-height: 50%;
}

.skillPillContainer {
  margin-top: 5px;
  flex-wrap: wrap;
  margin-bottom: 20px;
  display: flex;
}

.skillPill {
  border: 1px solid #1db954;
  border-radius: 20px;
  padding: 10px;
  margin-right: 5px;
}

.projectSummaryDetails h3 {
  font-size: 25px;
  margin: 0;
}

.projectSummaryDetails li {
  font-size: 15px;
  padding-bottom: 10px;
}

.skillsContainer {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-device-width: 480px) {
  .projectSummary img {
    display: none;
  }

  .skillPill {
    margin: 5px;
  }

  .projectSummaryDetails li {
    font-size: 15px;
  }
}
