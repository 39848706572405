.carouselControls {
  display: flex;
  align-items: center;
  color: rgb(155, 155, 155);
}

.slideCount {
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}
