.whySpotifyToWhyWebEngineer h2 {
  font-size: 32px;
}

.whySpotifyToWhyWebEngineer {
  padding: 20px;
}

.whySpotifyToWhyWebEngineer .arrowRightIcon {
  position: relative;
  top: 5px;
  left: 20px;
}

.whySpotifyToWhyWebEngineer .arrowRightIcon:hover {
  cursor: pointer;
  opacity: 0.8;
}
