.resumeBulletPoints {
  list-style: none;
  padding-left: 0;
}

.resumeBulletPoints li {
  padding: 7px;
  padding-left: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}
