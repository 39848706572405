.whySpotifyProjects {
  padding: 20px;
  margin-bottom: 50px;
}

.whySpotifyProjects h2 {
  font-size: 40px;
  font-weight: 900;
  margin-bottom: 5px;
  margin-top: 0px;
}

.whyProjectsSubHeading {
  font-size: 20px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 20px;
}
