.whySpotifyArticles {
  padding: 20px;
}

.whySpotifyArticles h2 {
  font-size: 30px;
  font-weight: 900;
  margin-bottom: 5px;
  margin-top: 0px;
}

.whyArticlesSubHeading {
  font-size: 20px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 20px;
}
