.resumeHeader {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
}

.resumeHeader h1 {
  font-size: 40px;
  margin-bottom: 10px;
}

.profileDetails {
  display: flex;
  gap: 20px;
}

.profileDetail {
  display: flex;
  align-items: center;
}

.email {
  margin-left: 4px;
}

.externalLinks {
  position: relative;
  right: 10px;
  bottom: 40px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
}

@media only screen and (max-device-width: 480px) {
  .resumeHeader h1 {
    margin-top: 50px;
    font-size: 30px;
  }

  .profileDetails {
    font-size: 10px;
    gap: 15px;
  }

  .externalLinks {
    justify-content: center;
    position: static;
    margin-top: 20px;
  }
}
