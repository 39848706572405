main {
  display: flex;
}

aside {
  background-color: #333;
  height: 100vh;
  position: absolute;
  z-index: 100;
}

.btnContainer {
  z-index: 100;
  margin-top: 10px;
  margin-left: 15px;
  color: #efd9ce !important;
}

.btnContainer:hover {
  cursor: pointer;
}

.container {
  margin-top: 70px;
}

.container a {
  color: #f9fafb;
  text-decoration: none;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
  display: block;
  margin: 20px;
}

.container a:hover {
  cursor: pointer;
}

button {
  cursor: pointer;
  margin: 1.25rem;
  border: none;
  padding: 0.5rem 1rem;
}
