.whyWebEngineerPage {
  padding: 20px;
}

.whyWebEngineerPage h1 {
  font-weight: normal;
}

.spotifyIcon {
  width: 80px;
  height: 80px;
}

.spotifyIconContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

@media only screen and (max-device-width: 480px) {
  .whyWebEngineerPage {
    padding: 30px;
  }

  .spotifyIconContainer {
    margin-bottom: 40px;
    margin-right: 50px;
  }

  .spotifyIcon {
    width: 50px;
    height: 50px;
  }

  .whyWebEngineerPage h1 {
    font-size: 20px;
  }
}
