.resume {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}

.resumeEducationProjects {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-right: 20px;
  gap: 20px;
  flex: 0.35;
}

@media only screen and (max-device-width: 480px) {
  .resume {
    display: block;
    padding: 0;
  }

  .resumeEducationProjects {
    margin-top: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}
