.resumeExperienceSection {
  flex: 0.6;
}

.experience {
  margin-bottom: 30px;
}

@media only screen and (max-device-width: 480px) {
  .resumeExperienceSection {
    padding-left: 20px;
    padding-right: 20px;
  }
}
