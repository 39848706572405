.articleTimelineIcon {
  object-fit: contain;
  background-color: #fff;
  border-radius: 100px;
}

.timelineCard:hover {
  cursor: pointer;
}

.timelineElement img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.articleHeading {
  margin: 0;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: normal;
}

.greyLine {
  background-color: #333;
  height: 2px;
  width: 0;
  margin: 0 auto;
}
