.connectedRequirementExplanation {
  font-size: 25px;
  margin-bottom: 200px;
  display: flex;
  justify-content: space-between;
}

.connectedRequirementExplanation:last-child {
  margin-bottom: 50px;
}

.requirement {
  flex: 0.5;
}

.requirement p {
  display: inline;
  font-size: 22px;
}

.explanation {
  flex: 0.5;
  font-size: 22px;
}

.explanation ul {
  margin: 0;
  padding: 0;
}

.explanation li {
  margin-bottom: 10px;
}

.requirementExplanationDivider {
  height: 200px;
  width: 5px;
  border-radius: 100px;
  margin: 0 40px;
}

@media only screen and (max-device-width: 480px) {
  .requirement p {
    font-size: 12px;
  }

  .explanation {
    font-size: 12px;
  }

  .connectedRequirementExplanation {
    margin-bottom: 120px;
  }

  .requirementExplanationDivider {
    width: 2px;
  }
}
