.resumeSectionTitle {
  font-size: 25px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.greenLine {
  background-color: #1db954;
  height: 2px;
  margin-top: 2px;
  margin-bottom: 10px;
}

@media only screen and (max-device-width: 480px) {
  .resumeSectionTitle {
    font-size: 20px;
  }
}
