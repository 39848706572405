.whySpotifyIntro h1 {
  margin-top: 0;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whySpotifyIntro p {
  font-size: 20px;
  font-weight: 400;
}

.whySpotifyIntro {
  font-weight: 700;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 200px;
  min-height: 90vh;
}

.wave {
  position: sticky;
  top: 0;
  left: 0;
  border-bottom-left-radius: 50% 20%;
  border-bottom-right-radius: 50% 20%;
}

.spotifyIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spotifyIcon {
  width: 100px;
  height: 100px;
}

@media only screen and (max-device-width: 480px) {
  .whySpotifyIntro {
    padding: 30px;
  }

  .whySpotifyIntro h1 {
    font-size: 30px;
  }

  .spotifyIcon {
    width: 80px;
    height: 80px;
  }
}
