.resumeInterests {
  background-color: #333;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 40px;
}

.resumeInterest {
  margin-bottom: 30px;
}

.resumeInterestsFlex {
  display: flex;
  /* gap: 10px; */
  justify-content: space-between;
}
