.subRow {
  display: flex;
  justify-content: space-between;
}

.roleLocation {
  margin-left: 5px;
  font-family: 'Playfair Display', serif;
}

.roleCompany {
  font-weight: bold;
}

.roleHeader {
  font-size: 15px;
}

.roleTitle {
  color: #1db954;
  font-weight: bold;
  font-size: 18px;
}

@media only screen and (max-device-width: 480px) {
  .subRow {
    display: block;
  }

  .roleLocation {
    margin-left: 0px;
  }

  .roleCompany {
    font-weight: bold;
  }

  .roleHeader {
    font-size: 12px;
  }

  .roleTitle {
    color: #1db954;
    font-weight: bold;
    font-size: 15px;
  }

  .roleDuration {
    font-family: 'Poppins', sans-serif;
    font-weight: normal;
  }
}
