.subText {
  font-size: 60px;
  height: 40px;
}

.typeWriterCursor {
  font-size: 70px;
}

.typeWriterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

@media only screen and (max-device-width: 480px) {
  .typeWriterContainer {
    padding: 0 20px;
    height: 68vh;
  }

  .subText {
    font-size: 40px;
  }

  .typeWriterCursor {
    font-size: 50px;
  }
}
